import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import ArticleTaster from "../components/article_taster"

const IndexPage = ({ data }) => {
    return (<Layout pageTitle="home" expanded={true}>
        <h1>I'm Max</h1>
        <p>I'm a physics PhD student at Heriot-Watt working in <a href="http://hwquantum.org">Jonathan Leach's group</a> on stuff to do with quantum optics.</p>
        <p>I like <a href="https://github.com/maxastyler">programming</a></p>
        <hr />
        <h2>Recent Blog Posts:</h2>
        {data.allFile.edges.map(({ node: { name, childMdx: { frontmatter: { title, date, tags }, id } } }, i) => {
            return (<ArticleTaster key={i} title={title}
                date={date}
                excerpt="" link={name} tags={tags} id={id} />)
        })}
    </Layout>
    )
}

export default IndexPage

export const query = graphql`
query {
  allFile(
    filter: {sourceInstanceName: {eq: "blog"}, childMdx: {body: {ne: null}, frontmatter: {draft: {ne: true}}}}
    sort: {fields: childMdx___frontmatter___date, order: DESC}
limit: 3
  ) {
    edges {
      node {
        id
        name
        childMdx {
id
          frontmatter {
            title
            date
tags
          }
        }
      }
    }
  }
}
`
