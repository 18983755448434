import React from "react"
import { Link } from "gatsby"
import Tags from "./tags"
import { motion } from "framer-motion"

const ArticleTaster = (({ link, title, date, excerpt, tags, id }) => {
    return (<>
        <Link to={`/blog/${link}`}> <motion.h2 layoutId={`${id}title`}>{title}</motion.h2></Link>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <motion.small layoutId={`${id}date`}>{date}</motion.small>
            <Tags tags={tags} layoutId={`${id}tags`} />
        </div>
        <p>{excerpt}</p>
    </>)
})

export default ArticleTaster
