import React from "react"
import "./layout.scss"
import { motion } from "framer-motion"
import { Link } from "gatsby"

const Tags = ({ tags = [], layoutId = null }) => {
    return (<motion.div className="tags" layoutId={layoutId}>
                {tags.map((tag, i) => <div key={i}><Link to={`/tags/${tag}`} >
            <small style={{ margin: "5px" }}>{`${tag}`}</small>
        </Link></div>)
        }
    </motion.div >)
}

export default Tags
